/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import styles from "./DropdownText.module.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ThemeContext } from "context-api/ThemeContext";
import { useOutsideClick } from "hooks/useOutsideClick";
import { Tooltip } from "../Tooltip";

interface IDropdownText {
  options: string[];
  handleSelectedOption: any;
  tooltip?: boolean | string;
  defaultSelection?: string;
  border?: boolean;
  width?: number | string;
  height?: number | string;
  tooltipOptions?: any;
  description?: boolean | string;
  disabled?: boolean;
}

export default function DropdownText({
  options,
  handleSelectedOption,
  tooltip = false,
  defaultSelection,
  border = false,
  width = "100%",
  height = "100%",
  tooltipOptions = [],
  description = false,
  disabled = false,
}: IDropdownText) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const wrapperRef: any = useRef(null);
  const { theme } = useContext(ThemeContext);

  useOutsideClick(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    if (defaultSelection) {
      handleSelect(defaultSelection);
    }
  }, [defaultSelection]);

  const handleSelect = (item) => {
    setSelectedOption(item);
    setIsOpen(false);
    handleSelectedOption(item);
  };
  return (
    <div
      className={styles.container}
      data-theme={theme}
      ref={wrapperRef}
      style={{
        border: border
          ? theme === "dark"
            ? "1px solid rgba(255,255,255,0.1)"
            : "1px solid rgba(0,0,0,0.1)"
          : "none",
        padding: border ? 8 : 0,
        width,
        height,
        cursor: disabled ? "no-drop" : "default",
        backgroundColor: disabled ? "rgba(100, 100, 100, 0.1)" : "inherit",
      }}
    >
      <div
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className={styles.selected_option_wrapper}
        data-disabled={disabled}
      >
        {tooltip ? (
          <Tooltip
            title={typeof tooltip === "string" ? tooltip : selectedOption}
          >
            <div className={styles.selected_option} data-theme={theme}>
              {selectedOption}
            </div>
          </Tooltip>
        ) : (
          <div className={styles.selected_option}>{selectedOption}</div>
        )}
        <KeyboardArrowDownIcon />
      </div>
      <div
        className={styles.open_menu_wrapper}
        data-active={isOpen}
        data-disabled={disabled}
        data-theme={theme}
      >
        {options?.map((el, index) => (
          <Tooltip
            key={index}
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: tooltipOptions[index]?.readable ?? "",
                }}
              />
            }
            placement="right"
          >
            <div
              className={styles.option}
              data-theme={theme}
              onClick={() => handleSelect(el)}
            >
              {description ? (
                <span>
                  <span
                    style={{
                      width: 30,
                      display: "inline-block",
                    }}
                  >
                    {el}
                  </span>
                  <span>{tooltipOptions[index]?.readable ?? ""}</span>
                </span>
              ) : (
                el
              )}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
