/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import { FilterContext } from "context-api/FilterContext";
import { DailyFlightsOverviewClient } from "lib/api/lfa/dailyFlightsOverview/DailyFlightsOverview";
import { useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import PriceTooltip from "./PriceTooltip";
import { useHistory } from "react-router-dom";
import DatePickerComponent from "../../common/DatePicker/DatepickerLastest";
import {
  CalendarMonth,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useOutsideClick } from "hooks/useOutsideClick";
import moment from "moment";
import { ThemeContext } from "context-api/ThemeContext";
import { DailyFlightsOverviewContext } from "context-api/DailyFlightsOverview";
import OpenMenu from "./OpenMenu";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { ICustomAtarevTable } from "types/types";
import { Tooltip } from "components/common/Tooltip";

function DailyFlightsOverview() {
  const [data, setData] = useState<ICustomAtarevTable>({
    data: [],
    columns: [],
  });
  const { setFilterList } = useContext(FilterContext);
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => setIsOpen(false));
  const { theme } = useContext(ThemeContext);
  const { date, setDate } = useContext(DailyFlightsOverviewContext);
  const { filterList } = useContext(FilterContext);

  useEffect(() => {
    setLoading(true);
    const params = {
      cabin: "All",
      agg_type: "all_markets",
      display_date: moment(date).format("YYYY-MM-DD"),
      currency: filterList.currency,
    };
    DailyFlightsOverviewClient.fetchDailyFlightsOverview({ data: params })
      .then((res: any) => {
        setData({
          data: res?.table?.data,
          columns: getColumns(res?.table?.labels),
        });
      })
      .catch(() => {
        setData({ data: [], columns: [] });
      })
      .finally(() => setLoading(false));
  }, [date]);

  const getColor = (value) => {
    const today = moment();
    const selected = moment(date);
    const daysDifference = selected.diff(today, "days");
    if (value && typeof value === "number") {
      if (daysDifference + 1 > 30) {
        return "#9c00ed";
      } else if (daysDifference + 1 < 30 && value < 50) {
        return "#ec4252"; //Kırmızı
      } else if (daysDifference + 1 < 30 && value >= 50) {
        return "#00ad92"; //Yeşil
      }
    }
    return "#9c00ed";
  };
  const getColumns = (labels) => {
    const array: any = [];
    [{ openMenu: "" }, ...labels].forEach((element) => {
      for (const [field, name] of Object.entries(element)) {
        if (
          field !== "carrier" &&
          field !== "maf" &&
          field !== "loadFactor" &&
          field !== "openMenu"
        ) {
          const obj = {
            name,
            field,
            cell: (row) => {
              return (
                <Tooltip title={row[field] ?? ""}>
                  <span className={"rdb-custom-cell"}>{row[field]}</span>
                </Tooltip>
              );
            },
          };
          array.push(obj);
        } else if (field === "openMenu") {
          const obj = {
            name,
            field,
            cell: (row) => <OpenMenu row={row} selectMenu={onRowClick} />,
            disabledHeader: true,
          };
          array.push(obj);
        } else if (field === "carrier") {
          const obj = {
            name,
            field,
            cell: (row) => {
              return (
                <div
                  className={styles.custom_table_row}
                  style={{
                    backgroundColor: row[field].color || "rgba(255,0,0,0.2)",
                  }}
                >
                  <span className={styles.row}>{row[field]?.value}</span>
                </div>
              );
            },
            valueGetter: (row) => row[field]?.value,
          };
          array.push(obj);
        } else if (field === "loadFactor") {
          const obj = {
            name,
            field,
            cell: (row) => {
              return (
                <Tooltip title={row?.LFLastupdatedAt ?? "-"}>
                  <span
                    className={"rdb-custom-cell"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 8,
                    }}
                  >
                    {row[field] && (
                      <span style={{ width: 30 }}>{`%${
                        row[field] ?? "-"
                      }`}</span>
                    )}
                    <span className={styles.bar_wrapper}>
                      <span
                        className={styles.bar}
                        style={{
                          backgroundColor: getColor(row[field]),
                          width:
                            row[field] &&
                            typeof row[field] === "number" &&
                            row[field] * 0.6,
                        }}
                      />
                    </span>
                  </span>
                </Tooltip>
              );
            },
          };
          array.push(obj);
        } else if (field === "maf") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip
                title={
                  <PriceTooltip
                    data={{
                      hoverTexts: row?.hoverTexts,
                      lastUpdated: row?.lastUpdated,
                    }}
                  />
                }
              >
                <span className={"rdb-custom-cell"}>{row[field]}</span>
              </Tooltip>
            ),
          };
          array.push(obj);
        }
      }
    });
    return array;
  };

  const onRowClick = (row, location = null) => {
    setLoading(true);
    setFilterList({
      origRegion: [],
      destRegion: [],
      origCountry: [],
      destCountry: [],
      origCityAirport: [],
      destCityAirport: [],
      pos: [],
      mainCompetitor: [],
      selectedCompetitors: [],
      salesChannel: [],
      cabin: [],
      paxType: [],
      currency: [],
    });

    setFilterList((prevState) => ({
      ...prevState,
      origCityAirport: row?.origin ? [row?.origin] : [],
      destCityAirport: row?.destination ? [row?.destination] : [],
      mainCompetitor: row?.competitor ? [row?.competitor] : [],
      selectedCompetitors: ["All"],
      pos: ["All"],
      salesChannel: ["All"],
      cabin: ["All"],
      paxType: ["All"],
    }));

    if (location === "lf") {
      setTimeout(() => {
        history.push(
          `/lowest-fare-calendar?view=month&cabin=${
            row?.cabinCode ?? "All"
          }&date=${moment(date).format("YYYY-MM-DD")}`
        );
      }, 4000);
      return;
    }
    if (location === "at") {
      setTimeout(() => {
        history.push(
          `/availability-trends?cabin=${row?.cabinCode ?? "All"}&date=${moment(
            date
          ).format("YYYY-MM-DD")}`
        );
      }, 3000);
      return;
    }
    if (location === "pe") {
      setTimeout(() => {
        history.push(
          `/price-evolution?cabin=${row?.cabinCode ?? "All"}&date=${moment(
            date
          ).format("YYYY-MM-DD")}`
        );
      }, 3000);
      return;
    }
    setTimeout(() => {
      history.push(
        `/lowest-fare-calendar?view=day&cabin=${
          row?.cabinCode ?? "All"
        }&date=${moment(date).format("YYYY-MM-DD")}`
      );
    }, 3000);
  };

  return (
    <Card variant="secondary">
      <div className={styles.card_header}>
        <span>Daily Flights Table</span>
        <div ref={ref}>
          <div className={styles.date_buttons_wrapper}>
            <CalendarMonth
              className={styles.datepicker_icon}
              onClick={() => setIsOpen(!isOpen)}
            />
            <span
              className={styles.today_button}
              onClick={() => setDate(new Date())}
              data-active={
                moment(new Date()).format("DD-MM-YYYY") !==
                moment(date).format("DD-MM-YYYY")
              }
              data-theme={theme}
            >
              Today
            </span>
            <span
              className={styles.arrow_left}
              onClick={() =>
                setDate(new Date(moment(date).subtract(1, "days").format()))
              }
              data-theme={theme}
            >
              <KeyboardArrowLeft />
            </span>
            <span
              className={styles.arrow_right}
              onClick={() =>
                setDate(new Date(moment(date).add(1, "days").format()))
              }
              data-theme={theme}
            >
              <KeyboardArrowRight />
            </span>
            <span className={styles.date_text}>
              {moment(date).format("DD MMM YYYY")}
            </span>
          </div>
          <div
            className={`${styles.datepicker_modal} ${
              !isOpen && styles.visible
            }`}
          >
            <Card variant="secondary">
              <Card variant="primary">
                <DatePickerComponent
                  value={date}
                  startDate={date}
                  onChangeDate={(date) => {
                    setDate(date);
                  }}
                />
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <div className={styles.table_wrapper}>
        <CustomAtarevTable
          data={data.data}
          columns={data.columns}
          onRowClick={onRowClick}
          pagination
          defaultRowsPerPage={15}
          loader={loading}
        />
      </div>
    </Card>
  );
}

export default DailyFlightsOverview;
