import { CircularProgress } from "@mui/material";
import axios from "axios";
import Card from "components/common/Card/Card";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";
import { LFAApiClient } from "lib/api/lfa";
import { ErrorHandler } from "lib/log/ErrorHandler";
import { formatTimeFromNumber } from "lib/utils/dateFormat";
import { useContext, useEffect, useState } from "react";
import cardInfoJsx from "./hoverText";
import styles from "./styles.module.css";
import { useHistory } from "react-router-dom";
import { FilterContext } from "context-api/FilterContext";
import moment from "moment";
import { useFullScreenListener } from "hooks/useFullScreenListener";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { ICustomAtarevTable } from "types/types";
import MenuComponent from "components/common/Menu";
import { Tooltip } from "components/common/Tooltip";

export default function RecommendationsTable({ filters }: any) {
  const [data, setData] = useState<ICustomAtarevTable>({
    data: [],
    columns: [],
  });
  const [activeStep, setActiveStep] = useState(169);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { setFilterList } = useContext(FilterContext);
  let history = useHistory();

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        const { origAirport, destAirport, competitors } = filters;
        const origs: string = origAirport.join(",") || "All";
        const dests: string = destAirport.join(",") || "All";
        const comps: string = competitors.join(",") || "All";
        const cabinCode: string =
          activeStep === 0 ? "FIRST" : activeStep === 85 ? "BUS" : "ECO";
        const results: any = await LFAApiClient.getRulesResults(
          origs,
          dests,
          comps,
          cabinCode
        );

        if (!ignore) setData({ data: results, columns: [] });
      } catch (err) {
        console.log({ err });
        ErrorHandler.error(`Cannot retrieve rules results`, err);
      }
    }
    fetchData().then(() => console.debug("Rules results download complete"));
    return () => {
      ignore = true;
    };
  }, [filters, activeStep]);

  const noUndef = (value: any | undefined) => {
    return value || "N/A";
  };
  const arrows = {
    UP: images.arrow_up,
    DOWN: images.arrow_down,
    HORIZONTAL: images.arrow_horizontal,
    NONE: images.arrow_none,
  };

  const columns = [
    {
      name: "",
      field: "actionArrow",
      cell: (row) => (
        <div className={styles.col_arrow}>
          <img src={arrows[row.actionArrow]} alt="" />
        </div>
      ),
      disabledHeader: true,
    },
    {
      name: "ACTION",
      field: "action",
      cell: (row) => (
        <Tooltip title={row.action || ""}>
          <span className={"rdb-custom-cell"}>{row.action}</span>
        </Tooltip>
      ),
    },
    {
      name: "FLIGHT",
      field: "hostFlight",
      cell: (row) => {
        let flightNumber = row.hostFlight.match(/[a-zA-Z]+(\d+)/);
        return (
          <Tooltip title={row.hostFlight || ""}>
            <span className={"rdb-custom-cell"}>
              {flightNumber ? flightNumber[1] : row.hostFlight}
            </span>
          </Tooltip>
        );
      },
    },
    {
      name: "O&D",
      field: "hostFlightOD",
      cell: (row) => (
        <Tooltip title={row.hostFlightOD || ""}>
          <span className={"rdb-custom-cell"}>{row.hostFlightOD}</span>
        </Tooltip>
      ),
    },
    {
      name: "DEPT DATE",
      field: "hostFlightDepartureDate",
      cell: (row) => (
        <Tooltip title={row.hostFlightDepartureDate || ""}>
          <span className={"rdb-custom-cell"}>
            {`${row.hostFlightDepartureDate
              .toString()
              .slice(0, 4)}-${row.hostFlightDepartureDate
              .toString()
              .slice(4, 6)}-${row.hostFlightDepartureDate.toString().slice(6)}`}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "DOW",
      field: "dayOfWeekString",
      cell: (row) => (
        <Tooltip title={row.dayOfWeekString || ""}>
          <span className={"rdb-custom-cell"}>{row.dayOfWeekString}</span>
        </Tooltip>
      ),
    },
    {
      name: "DEPT TIME",
      field: "hostFlightDepartureTime",
      valueGetter: (row) =>
        `${formatTimeFromNumber(row.hostFlightDepartureTime)}`,
      cell: (row) => (
        <Tooltip
          title={formatTimeFromNumber(row.hostFlightDepartureTime) || ""}
        >
          <span className={"rdb-custom-cell"}>
            {formatTimeFromNumber(row.hostFlightDepartureTime)}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "AVLB CLS",
      field: "hostFlightClass",
      cell: (row) => (
        <Tooltip title={row.hostFlightClass || ""}>
          <span className={"rdb-custom-cell"}>{row.hostFlightClass}</span>
        </Tooltip>
      ),
    },
    {
      name: "VAR MAF",
      field: "maf",
      cell: (row) => (
        <Tooltip title={row.maf || ""}>
          <span className={"rdb-custom-cell"}>{row.maf}</span>
        </Tooltip>
      ),
    },
    {
      name: "HOST FARE",
      field: "hostFare",
      cell: (row) => (
        <div
          style={{
            backgroundColor: "rgba(20, 74, 255, 0.1)",
            width: "100%",
            height: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title={row.hostFare || ""}>
            <span className={"rdb-custom-cell"}>{row.hostFare}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      name: "COMP FARE",
      field: "mainCompetitorFare",
      valueGetter: (row) => `${noUndef(row.mainCompetitorFare)}`,
      cell: (row) => (
        <div
          style={{
            backgroundColor: "rgba(85, 4, 217, 0.1)",
            width: "100%",
            height: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title={row.mainCompetitorFare || ""}>
            <span className={"rdb-custom-cell"}>{row.mainCompetitorFare}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      name: "COMP",
      field: "mainCompetitorCarrierCode",
      cell: (row) => (
        <Tooltip title={row?.mainCompetitorCarrierCode || ""}>
          <span className={"rdb-custom-cell"}>
            {row?.mainCompetitorCarrierCode}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "CUR LF%",
      field: "hostFlightBookedLF",
      cell: (row) => {
        return (
          <Tooltip title={row.hostFlightBookedLF || ""}>
            <span className={"rdb-custom-cell"}>{row.hostFlightBookedLF}</span>
          </Tooltip>
        );
      },
    },
    {
      name: "EXP LF%",
      field: "hostFlightExpectedLF",
      cell: (row) => (
        <Tooltip title={row.hostFlightExpectedLF || ""}>
          <span className={"rdb-custom-cell"}>{row.hostFlightExpectedLF}</span>
        </Tooltip>
      ),
    },
    {
      name: "CUR AVLB",
      field: "hostFlightSeatsAvailable",
      cell: (row) => (
        <Tooltip title={row.hostFlightSeatsAvailable || ""}>
          <span className={"rdb-custom-cell"}>
            {row.hostFlightSeatsAvailable}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "EFF CLASS",
      field: "effectiveClass",
      cell: (row) => (
        <Tooltip title={row.effectiveClass || ""}>
          <span className={"rdb-custom-cell"}>{row.effectiveClass}</span>
        </Tooltip>
      ),
    },
    {
      name: "EFF FARE",
      field: "effectiveFare",
      cell: (row) => (
        <Tooltip title={row.effectiveFare || ""}>
          <span className={"rdb-custom-cell"}>{row.effectiveFare}</span>
        </Tooltip>
      ),
    },
    {
      name: "EFF AVLB",
      field: "effectiveAvailability",
      cell: (row) => (
        <Tooltip title={row.effectiveAvailability || ""}>
          <span className={"rdb-custom-cell"}>{row.effectiveAvailability}</span>
        </Tooltip>
      ),
    },
    {
      name: "COMP AVLB",
      field: "mainCompetitorSeatsAvailable",
      valueGetter: (row) => `${noUndef(row.mainCompetitorSeatsAvailable)}`,
      cell: (row) => (
        <Tooltip title={row.mainCompetitorSeatsAvailable || ""}>
          <span className={"rdb-custom-cell"}>
            {row.mainCompetitorSeatsAvailable}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "COMP DT",
      field: "mainCompetitorDepartureTime",
      valueGetter: (row) => `${noUndef(row.mainCompetitorDepartureTime)}`,
      cell: (row) => (
        <Tooltip title={row.mainCompetitorDepartureTime || ""}>
          <span className={"rdb-custom-cell"}>
            {row.mainCompetitorDepartureTime}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "CHEAP FARE",
      field: "lowestFare",
      valueGetter: (row) => `${noUndef(row.lowestFare)}`,
      cell: (row) => (
        <div
          style={{
            backgroundColor: "rgba(0, 173, 146, 0.1)",
            width: "100%",
            height: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title={row.lowestFare || ""}>
            <span className={"rdb-custom-cell"}>{row.lowestFare}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      name: "CHEAP DT",
      field: "lowestDepartureTime",
      valueGetter: (row) => `${noUndef(row.lowestDepartureTime)}`,
      cell: (row) => (
        <Tooltip title={row.lowestDepartureTime || ""}>
          <span className={"rdb-custom-cell"}>{row.lowestDepartureTime}</span>
        </Tooltip>
      ),
    },
    {
      name: "SENT STAT",
      field: "sendStat",
      cell: (_row) => (
        <Tooltip title={"08:12"}>
          <span className={"rdb-custom-cell"}>08:12</span>
        </Tooltip>
      ),
      valueGetter: (_row) => `08:12`,
    },

    {
      name: "RULE NAME",
      field: "ruleName",
      valueGetter: (row) => noUndef(row.ruleName),
      cell: (row) => (
        <Tooltip title={cardInfoJsx(row?.hoverText)}>
          <span className={"rdb-custom-cell"}>{row.ruleName}</span>
        </Tooltip>
      ),
    },

    {
      name: "LAST UPDATED",
      field: "createdAt",
      cell: (row) => (
        <div
          style={{
            backgroundColor: "rgba(20, 74, 255, 0.1)",
            width: "150px",
            height: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className={"rdb-custom-cell"}>{row.createdAt}</span>
        </div>
      ),
    },
  ];

  const getAuthToken = () => {
    let token: any = null;
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].split("=");
      if (cookie[0].trim().toLocaleLowerCase() === "token") {
        token = cookie[1];
      }
    }
    return token;
  };

  const handleOnExport = async (isExcelExport) => {
    const { origAirport, destAirport } = filters;
    setIsLoading(true);
    const url = isExcelExport
      ? `/api/lfa/rules-results/report?originCode=${origAirport}&destCode=${destAirport}`
      : `/api/msdv2/reports/inventory-changes?orig_city_airport=${origAirport}&dest_city_airport=${destAirport}`;
    try {
      const response = await axios({
        url,
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
        method: "GET",
        responseType: "blob", // important
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "data.csv";
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRowClick = (row) => {
    setLoading(true);
    setFilterList({
      origRegion: [],
      destRegion: [],
      origCountry: [],
      destCountry: [],
      origCityAirport: [],
      destCityAirport: [],
      pos: [],
      mainCompetitor: [],
      selectedCompetitors: [],
      salesChannel: [],
      cabin: [],
      paxType: [],
      currency: [],
    });
    setTimeout(() => {
      setFilterList((prevState) => ({
        ...prevState,
        origCityAirport: row?.hostFlightOD
          ? [row?.hostFlightOD?.slice(0, 3)]
          : [],
        destCityAirport: row?.hostFlightOD
          ? [row?.hostFlightOD?.slice(-3)]
          : [],
        mainCompetitor: row?.hoverText?.competitor
          ? [row?.hoverText?.competitor]
          : [],
        selectedCompetitors: ["All"],
        pos: ["All"],
        salesChannel: ["All"],
        cabin: ["All"],
        paxType: ["All"],
      }));
    }, 2000);
    setTimeout(() => {
      history.push(
        `/lowest-fare-calendar?view=day&date=${moment(
          `${row.hostFlightDepartureDate
            ?.toString()
            .slice(0, 4)}-${row.hostFlightDepartureDate
            ?.toString()
            .slice(4, 6)}-${row.hostFlightDepartureDate?.toString().slice(6)}`
        ).format("YYYY-MM-DD")}`
      );
      setLoading(false);
    }, 3000);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  useFullScreenListener(() =>
    setIsFullScreen(document.fullscreenElement !== null)
  );
  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      const selectedElement = document.querySelector(`.custom-id-table`);
      selectedElement?.requestFullscreen();
    }
  }

  return (
    <Card variant="secondary">
      <div className={styles.header}>
        <div>
          <MenuComponent
            options={[
              {
                title: "Export Excel",
                handleClick: () => handleOnExport(true),
              },
              {
                title: "Log Report",
                handleClick: () => handleOnExport(false),
              },
            ]}
          >
            <div className={styles.buttons_wrapper}>
              <div className={styles.export_button}>
                {isLoading ? (
                  <CircularProgress
                    style={{ color: "white", width: 24, height: 24 }}
                  />
                ) : (
                  <img src={images.excel} alt="" />
                )}
              </div>
            </div>
          </MenuComponent>
        </div>

        <div className={styles.header_button_wrapper} data-theme={theme}>
          <span
            className={styles.button}
            //   onClick={() => setActiveStep(0)}
            data-active={activeStep === 0}
            data-theme={theme}
          >
            First
          </span>
          <span
            className={styles.button}
            onClick={() => setActiveStep(85)}
            data-active={activeStep === 85}
            data-theme={theme}
          >
            Bus
          </span>
          <span
            className={styles.button}
            onClick={() => setActiveStep(169)}
            data-active={activeStep === 169}
            data-theme={theme}
          >
            Eco
          </span>
          <span
            className={styles.active_button}
            style={{ left: activeStep }}
          ></span>
        </div>
      </div>
      <div
        className={`${styles.card_body} custom-id-table`}
        style={{
          backgroundImage: isFullScreen ? "var(--bg-image)" : "none",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className={styles.fullscreen_button_wrapper}>
          <span
            onClick={() => {
              setIsFullScreen(!isFullScreen);
              toggleFullScreen();
            }}
            className={styles.fullscreen_button}
          >
            <Tooltip title="Full Screen">
              {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
            </Tooltip>
          </span>
        </div>
        <div className={styles.table_wrapper}>
          {loading && <CircularProgress className={styles.progres} />}
          <CustomAtarevTable
            data={data.data}
            columns={columns}
            pagination
            onRowClick={onRowClick}
            height={500}
            nthChild
          />
        </div>
      </div>
    </Card>
  );
}
