/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Dropdown.module.css";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";
import { Clear, Done, KeyboardArrowDown } from "@mui/icons-material";
import upperCaseLetters from "utils/upperCaseLetters";
import { useOutsideClick } from "hooks/useOutsideClick";

interface IDropdown {
  data: string[];
  added: string[];
  setAdded: Dispatch<SetStateAction<string[]>>;
  singleSelected?: boolean;
  width?: number | string;
  border?: boolean;
  disabled?: boolean;
  getCarriers?: any;
  required?: boolean;
  max?: number;
  ineligible?: string[];
  setSearchFilter?: any;
}

export default function Dropdown({
  data,
  added,
  setAdded,
  singleSelected = false,
  width = 204,
  border = false,
  disabled = false,
  getCarriers = null,
  required = false,
  max = -1,
  ineligible = [],
  setSearchFilter = null,
}: IDropdown) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { theme } = useContext(ThemeContext);
  const inputElement: any = useRef(null);
  const wrapperRef: any = useRef(null);

  useOutsideClick(wrapperRef, () => {
    setIsOpen(false);
    setSearch("");
  });

  useEffect(() => {}, [ineligible]);

  useEffect(() => {
    if (disabled) setAdded([]);
    else setAdded(added);
  }, [disabled]);

  useEffect(() => {
    optionsControl();
  }, [data]);

  const handleAdded = async (item: string) => {
    if (added.length > 0) {
      const bool = added.find((str) => (str === item ? true : false));

      if (!bool) {
        if (singleSelected) {
          setAdded([item]);
        } else {
          if (max > 0 && added.length < max) {
            setAdded([...added, item]);
          } else if (max === -1) setAdded([...added, item]);
        }
      } else handleRemove(item);
    } else {
      setAdded([...added, item]);
    }
  };

  const optionsControl = () => {
    if (data?.length > 0) {
      let deleted: string[] = [];
      added.forEach((str) => {
        if (!data?.includes(str)) {
          deleted.push(str);
        }
      });
      if (added.some((el) => deleted.includes(el))) {
        const array = added.filter((el) => !deleted.includes(el));
        setAdded(array);
      }
    }
  };

  const handleRemove = (item: string) => {
    if (added.length > 0) {
      const array = added.filter((str) => str !== item);
      setAdded(array);
    }
  };

  const getColor = (item: string) => {
    const color = "transparent";
    if (getCarriers) {
      if (getCarriers[item] !== undefined) {
        return getCarriers[item].color;
      }
      return color;
    }
  };

  const filteredData = (): string[] => {
    if (setSearchFilter) {
      const response = data?.filter((el) => !added?.includes(el));
      return [...added, ...response];
    }
    const result = [...(data ?? [])]?.filter((item) => {
      const index = upperCaseLetters(item).indexOf(upperCaseLetters(search));
      return index === 0 ? item : "";
    });
    const response = result?.filter((el) => !added?.includes(el));
    return [...added, ...response];
  };

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleSearchFilter, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const handleSearchFilter = () => {
    if (setSearchFilter) setSearchFilter(search);
  };

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current?.focus();
    }
  }, [isOpen]);

  const dropdownStyles = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      return rect.right + rect.width > window.innerWidth;
    }
    return false;
  };
  return (
    <div
      className={`${styles.container} ${required && styles.required}`}
      ref={wrapperRef}
      data-active={disabled}
      data-theme={theme}
    >
      <div className={styles.dropdown_input}>
        {added?.map((item, index) => {
          return (
            <div key={index} className={styles.item_wrapper} data-theme={theme}>
              {border && (
                <span
                  className={styles.dropdown_item_border}
                  style={{ borderColor: getColor(item) }}
                />
              )}
              <span
                style={{ marginLeft: border ? 0 : 8 }}
                className={styles.item_text}
                data-theme={theme}
              >
                {item}
              </span>

              <Clear
                className={styles.close_icon}
                data-active={disabled}
                onClick={() => {
                  handleRemove(item);
                }}
              />
            </div>
          );
        })}
      </div>
      <div
        className={styles.onClick}
        data-active={disabled}
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <KeyboardArrowDown />
      </div>
      <div
        className={`${styles.open_menu} ${
          dropdownStyles() ? styles.left_align : styles.right_align
        }`}
        data-active={isOpen}
        style={{ minWidth: width }}
        data-theme={theme}
      >
        <div className={styles.open_menu_search_wrapper} data-theme={theme}>
          <input
            type="text"
            placeholder="Search"
            value={search}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onChange={(e) => setSearch(e.target.value)}
            ref={inputElement}
          />
          <img src={images.search} alt="" className={styles.search_icon} />
        </div>
        <div className={styles.open_menu_scroll}>
          {filteredData()?.map((item, index) => {
            return (
              <div
                key={index}
                className={styles.open_menu_items}
                onClick={() => {
                  if (!ineligible.includes(item)) {
                    handleAdded(item);
                  }
                }}
                data-selected={added.includes(item)}
                data-theme={theme}
                data-ineligible={ineligible.includes(item)}
              >
                {border && (
                  <span
                    className={styles.open_menu_item_border}
                    style={{ borderColor: getColor(item) }}
                  />
                )}
                <span
                  className={styles.options_item}
                  style={{
                    marginLeft: border ? 0 : 24,
                  }}
                >
                  {item}
                </span>
                {added.includes(item) && !singleSelected && (
                  <Done style={{ color: "white", width: 24 }} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
