export default function CompareFilterObjects(filter1, filter2) {
  for (const key in filter1) {
    // Eğer filter1'in key'i filter2'nin de aynı bir key'e sahip değilse, eşitlik false dönecek.
    if (!filter2.hasOwnProperty(key)) {
      return false;
    }

    // Eğer key aynı değilse veya değerler aynı değilse, eşitlik false dönecek.
    if (filter1[key].toString() !== filter2[key].toString()) {
      return false;
    }
  }

  // Tüm key-value çiftleri eşitse, eşitlik true dönecek.
  return true;
}
